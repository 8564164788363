<template>
  <div class="table__wrapper">
    <div class="table__sidebar">
      <div class="table__sidebar__inner--nse">
        <span
          class="table__sidebar__name-filter"
          :class="{
            current: currentSort === 'type' ? 'current' : '',
            asc: currentSortDir === 'asc' ? 'asc' : '',
          }"
          @click="sort('type')"
        >
          Type
        </span>
        <span
          v-for="entry in sortedSausages"
          class="table__sidebar__item"
          :key="entry.id"
          :id="kebabCase(entry.type) + '-sidebar'"
          @click="typeClickHandler(kebabCase(entry.type))"
          >{{ entry.type }}
          <span class="type-link-icon"></span>
        </span>
      </div>
    </div>

    <div class="table__data-wrapper--nse" id="madeTable">
      <table class="table" cellpadding="0" cellspacing="0">
        <tr class="table__header-wrapper">
          <th
            class="type-mobile"
            :class="{
              current: currentSort === 'type' ? 'current' : '',
              asc: currentSortDir === 'asc' ? 'asc' : '',
            }"
            @click="sort('type')"
          >
            Type
          </th>
          <th
            :class="{
              current: currentSort === 'id' ? 'current' : '',
              asc: currentSortDir === 'asc' ? 'asc' : '',
            }"
            @click="sort('id')"
          >
            Number
          </th>
          <th
            :class="{
              current: currentSort === 'rating' ? 'current' : '',
              asc: currentSortDir === 'asc' ? 'asc' : '',
            }"
            @click="sort('rating')"
          >
            Rating
          </th>
          <th
            :class="{
              current: currentSort === 'episode' ? 'current' : '',
              asc: currentSortDir === 'asc' ? 'asc' : '',
            }"
            @click="sort('episode')"
          >
            Episode
          </th>
          <th
            :class="{
              current: currentSort === 'episodeLength' ? 'current' : '',
              asc: currentSortDir === 'asc' ? 'asc' : '',
            }"
            @click="sort('episodeLength')"
          >
            Episode Length
          </th>
          <th
            :class="{
              current: currentSort === 'song' ? 'current' : '',
              asc: currentSortDir === 'asc' ? 'asc' : '',
            }"
            @click="sort('song')"
          >
            Mr. Sausage Sings
          </th>
        </tr>

        <tr
          class="table__row"
          v-for="entry in sortedSausages"
          :key="entry.id"
          :id="kebabCase(entry.type)"
          @click="typeClickHandler(kebabCase(entry.type))"
        >
          <td class="type-mobile">
            {{ entry.type }}
            <span class="type-link-icon"></span>
          </td>
          <td>
            <span v-if="entry.id < 10">00{{ entry.id }}</span>
            <span v-else-if="entry.id > 9 && entry.id < 100"
              >0{{ entry.id }}</span
            >
            <span v-else>{{ entry.id }}</span>
          </td>
          <td>
            <span>{{ entry.rating }} </span>
          </td>
          <td>
            <a
              :href="`https://www.youtube.com/watch?v=${entry.episodeID}`"
              target="_blank"
              rel="noopener"
              >{{ entry.episode }}</a
            >
          </td>
          <td>{{ entry.episodeLength }}</td>
          <td>
            {{ entry.song }}
          </td>
        </tr>
      </table>
    </div>

    <div
      class="link-copied-info"
      :class="{ 'link-copied-info--show': showLinkInfo === true }"
    >
      Link was copied to clipboard!
    </div>
  </div>
</template>

<script>
  // Sorting: https://www.raymondcamden.com/2018/02/08/building-table-sorting-and-pagination-in-vuejs

  export default {
    computed: {
      sausages() {
        return this.$store.state.nseData;
      },
      sortedSausages: function () {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.sausages.slice().sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === "desc") modifier = -1;
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return modifier;
          return 0;
        });
      },
    },
    data: function () {
      return {
        currentSort: "id",
        currentSortDir: "desc",
        showLinkInfo: false,
      };
    },
    methods: {
      kebabCase: function (string) {
        return string.toLowerCase().replace(/\s+/g, "-");
      },
      sort: function (s) {
        if (s === this.currentSort) {
          this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
        }
        this.currentSort = s;
      },
      typeClickHandler: function (typestring) {
        const link = location.origin + "?nse=" + typestring;

        navigator.clipboard.writeText(link);

        if (this.showLinkInfo === false) {
          this.showLinkInfo = true;

          window.setTimeout(() => {
            this.showLinkInfo = false;
          }, 1500);
        }
      },
    },
    mounted: function () {
      tableScroll();
    },
    name: "Headline",
    props: {
      message: String,
      tag: Number,
    },
  };

  function tableScroll() {
    let table = document.querySelector(".table__data-wrapper--nse");
    let tableSideBar = document.querySelector(".table__sidebar__inner--nse");

    table.addEventListener("scroll", function () {
      tableSideBar.style.marginTop = table.scrollTop * -1 + "px";
    });
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .table {
    background-color: white;
    width: 100%;
    overflow: auto;
    position: relative;
    white-space: nowrap;
  }

  .table__header-wrapper {
    left: -1px;
    position: sticky;
    top: -1px;
  }

  .table__sidebar {
    color: white;
    display: none;
  }

  @media (min-width: 600px) {
    .table__sidebar {
      display: block;
      min-width: 400px;
    }
  }

  .table__sidebar span {
    display: block;
    white-space: nowrap;
  }

  .table__sidebar span.is-current {
    animation-name: highlightSidebar;
    animation-duration: 0.5s;
    animation-iteration-count: 7;
  }

  .table__wrapper {
    background-color: var(--light-mode-table-bg-color);
    border-radius: 15px;
    display: flex;
    font-family: "Nunito", "Arial", sans-serif;
    max-width: 1100px;
    max-height: 459px;
    margin: 0 auto 60px auto;
    overflow-y: hidden;
    box-shadow: 0 0 15px 0 var(--light-mode-search-bar-submit-bg-color);
  }

  .table__data-wrapper--nse {
    overflow: auto;
  }

  th {
    background-color: var(--light-mode-table-bg-color);
    color: white;
    cursor: pointer;
    position: relative;
    text-align: left;
  }

  th::after {
    display: none;
  }

  th.current::after,
  .table__sidebar__name-filter.current::after {
    border-bottom: 10px solid white;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: "";
    display: block;
    position: absolute;
    right: 5px;
    top: 38%;
    transition: transform 0.25s ease-out;
  }

  th.current:not(.asc)::after,
  .table__sidebar__name-filter:not(.asc)::after {
    transform: rotate(-180deg);
  }

  .table__sidebar__name-filter {
    background-color: var(--light-mode-table-bg-color);
    cursor: pointer;
    position: relative;
    top: 0;
  }

  td,
  th {
    border: 0;
  }

  td span {
    padding: 0;
  }

  td a {
    color: black;
  }

  td,
  th,
  span {
    font-size: 10px;
    padding: 10px 20px;
  }

  @media (min-width: 600px) {
    td,
    th,
    span {
      font-size: 14px;
    }
  }

  tr.is-current {
    animation-name: highlightRow;
    animation-duration: 0.5s;
    animation-iteration-count: 7;
  }

  .type-mobile {
    display: block;
  }

  .type-link-icon {
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 0 0 0 8px;
    padding: 0;
    content: "";
    display: inline-block !important;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACiElEQVR4nO2ZTWoUQRSAKztDormCGF14iGjiiMsERcgVks2sIgzmIHFE8AzRuFFIVBByAY1uXCgYIbbGSSYbwU8e84Si0j/VPenqCvQHAz1NVc37pru63qs2pqWlJXqA28BjYA840o8c94GOiR3gKvCGYl5LWxMjwDyQ4E8CzJmYAG4Ax5TnZzRXRu554CQlyE29SlP6keNnKe12YpX4C6zl9HmYInMrbOR+El2PvltOv40wUZ8OZKGqhPUn2HwwoQGuA4dVJQTgotP/twkJMAG8G0dCAC45YxyakAB3UiZqr8I4HWeM9/VEnB3AUyeAl3KVKozzotHJDnxyAlisMMaDlKu6UE/E2UEMrR/fBS7o+bvAPnAA3M/p39U5ZbMdVMIREYkZPbcM/LECOyghkQCzTYh8LJAQvntKnEjaElxCA1otkJDv9zwlOo1IOMFlSSw7a07v3ErIAwBYAl45bcJISAaqpeie1glHOidWS0jM6BwiuARwJac8LZrYvhJJrRO7oDw9K4mdWh+xOZVdKQltsyKpuJa7A8mdJO2g7hU7R0Jyp0VrxS6UiLGy69kJ4HmV6DrtljzmxEpTEvO+5SnwtUBiN3gNYe0AJr6VHfCtQEIYmNBwep3ILU/11voCfJZjPTfpPGIHTWwou6xVGEfkGi1P+04AmxXGmEjJnZ7UE3EGmi/ZlE4V9NHc7O4go5XWZqpk/7R64m19EfuLTI8p8Qu4ZkLDKC0vfWtlSAybLE/7TjDPx5BofNfcZb1g3ykuif/oOzuXLa0Kp3VTuZOyAxiPhABc1s2zsgyj2CiwkRePJV9W/gBumhgBZjNuM5dtqeVN7DB64/RIS9JB0PK0paXFnAX/AHEX6S1QU5bLAAAAAElFTkSuQmCC");
    background-size: cover;
  }

  .table__sidebar__item {
    cursor: pointer;
    position: relative;
  }

  .table__sidebar__item:hover {
    text-decoration: underline;
  }

  .link-copied-info {
    background-color: white;
    padding: 16px 32px;
    border-radius: 16px;
    font-size: 16px;
    position: fixed;
    bottom: -128px;
    left: 50%;
    transform: translateX(-50%);
    transition: bottom 0.25s ease-in-out;
  }

  .link-copied-info--show {
    bottom: 32px;
  }

  @media (min-width: 600px) {
    .type-mobile {
      display: none;
    }
  }

  @keyframes highlightRow {
    0% {
      background-color: rgb(255, 255, 255);
    }

    50% {
      background-color: rgb(235, 235, 235);
    }

    100% {
      background-color: rgb(255, 255, 255);
    }
  }

  @keyframes highlightSidebar {
    0% {
      background-color: #084361;
    }

    50% {
      background-color: #0b4f72;
    }

    100% {
      background-color: #084361;
    }
  }
</style>
